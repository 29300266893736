@charset "UTF-8";
/* =============================================================================


     font-face


============================================================================= */
@font-face {
  font-family: "tsukuB";
  src: url("../font/B.woff") format("woff"), url("../font/B.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "tsukuD";
  src: url("../font/D.woff") format("woff"), url("../font/D.otf") format("opentype");
  font-display: swap;
}

/* =============================================================================


     font-extend


============================================================================= */
input[type="submit"], input[type="button"] {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: bold;
  font-style: normal;
}

.mv-border, .header-nav {
  font-family: tsukuB, "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 700;
}

.footer-item, .footer-copy {
  font-family: tsukuD, "Noto Serif JP", 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 500;
}

/***********************
   基本サイズ
***********************/
body {
  font-size: 1.6rem;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", "Noto Serif JP", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.53125;
}

[data-target] {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: normal;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  min-width: 1366px;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1365px) {
  body {
    font-size: 1.5rem;
    min-width: 1080px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

@media (max-width: 767px) {
  main {
    margin-top: 80px;
  }
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

form {
  margin: 0;
  padding: 0;
}

form button {
  width: auto;
  display: inline-block;
  padding: 0.6em 2em;
  margin: 0;
  background-image: none;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.3s ease 0s;
}

form button:hover, form button:focus {
  outline: none;
}

form button:hover {
  opacity: 0.7;
}

form button ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 0;
  background-image: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f2f2f2;
}

textarea:hover, textarea:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

input[type="submit"], input[type="button"] {
  width: auto;
  display: block;
  padding: 0;
  margin: 0;
  background-image: none;
  background: #000;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.2s ease 0s;
  font-size: 3.7rem;
  line-height: 115px;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="button"]:hover, input[type="button"]:focus {
  outline: none;
}

input[type="submit"]:hover, input[type="button"]:hover {
  opacity: 0.7;
}

input[type="submit"] ::-moz-focus-inner, input[type="button"] ::-moz-focus-inner {
  padding: 0;
  border: none;
}

@media (max-width: 767px) {
  input[type="submit"], input[type="button"] {
    font-size: 1.8rem;
    line-height: 60px;
  }
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + span {
  cursor: pointer;
  display: inline-block;
  margin: 0 0.2em 0;
  padding: 0 0 0 1.2em;
  position: relative;
}

/* input[type="radio"] + span::before {
  -webkit-transform: translateY(-50%);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 0%;
  content: "";
  display: block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}

input[type="radio"] + span::after {
  -webkit-transform: translateY(-50%);
  background: black;
  border: 1px solid transparent;
  border-radius: 0%;
  content: "";
  height: 8px;
  left: 4px;
  opacity: 0;
  padding: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  width: 8px;
} */
input[type="radio"]:checked + span::after {
  opacity: 1;
}

input[type="checkbox"] {
  width: auto;
  -webkit-appearance: auto;
}

input[type="radio"] + span::before,
.swpm-remember-radio:before {
  -webkit-transform: translateY(-50%);
  background: #fff;
  border: 1px solid #000;
  border-radius: 2px;
  content: "";
  display: block;
  height: 1em;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}

input[type="radio"] + span::after,
.swpm-remember-radio:after {
  -webkit-transform: translateY(-50%) rotate(-45deg);
  border-bottom: 3px solid #000;
  border-left: 3px solid #000;
  content: "";
  display: block;
  height: 0.6em;
  left: 0;
  margin-top: -0.2em;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.3s ease 0s;
  width: 1em;
}

input[type="radio"]:checked + span::after {
  opacity: 1;
}

.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1366px;
  height: inherit;
  position: relative;
  min-width: 1366px;
}

@media (max-width: 1365px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
    min-width: unset;
    width: 100%;
    max-width: 1080px;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.sub-mv {
  position: relative;
  overflow: hidden;
  height: 776px;
  -webkit-text-orientation: upright;
  text-orientation: upright;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 100%;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  .sub-mv {
    min-width: unset;
    height: 400px;
  }
}

.sub-mv._menu {
  background-image: url(../img/menu/mv.jpg);
}

@media (max-width: 767px) {
  .sub-mv._menu {
    background-image: url(../img/menu/mv_sp.jpg);
  }
}

.sub-mv._shop, .sub-mv._contact {
  background-image: url(../img/shop/mv2.jpg);
}

@media (max-width: 767px) {
  .sub-mv._shop, .sub-mv._contact {
    background-image: url(../img/shop/mv2_sp.png);
  }
}

.sub-mv._about {
  background-image: url(../img/about/mv.jpg);
}

.sec-inner {
  width: 1180px;
  margin: auto;
}

@media (max-width: 1365px) {
  .sec-inner {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .sec-inner {
    width: 100%;
  }
}

.sub-mv-img {
  width: 100%;
  overflow: hidden;
}

.sub-mv-img img {
  object-fit: cover;
  font-family: "object-fit:cover";
  height: 100%;
  width: 100%;
}

.sub-menu {
  background: rgba(0, 0, 0, 0.6);
  /*   mix-blend-mode: multiply; */
  display: flex;
  justify-content: center;
  height: 200px;
  width: 100%;
  -webkit-text-orientation: upright;
  text-orientation: upright;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  flex-direction: column;
  padding: 10px 0px;
}

@media (max-width: 767px) {
  .sub-menu {
    display: none;
  }
}

.su-menu-list {
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  margin-left: 30px;
}

.su-menu-item {
  height: 100%;
  padding: 0.25em 1.5em;
  font-size: 2.5rem;
  position: relative;
  border-left: 1px solid #fff;
}

.su-menu-item:before {
  content: "●";
  color: #c69c6d;
  font-size: 80%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.su-menu-item:nth-last-child(1) {
  border-right: 1px solid #fff;
}

.su-menu-link {
  transition: 0.2s;
  color: #fff;
  display: inline-block;
  padding-top: 1em;
}

.su-menu-link:hover {
  color: #c69c6d;
}

.mv-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 776px;
  -webkit-text-orientation: upright;
  text-orientation: upright;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.mv-video._fs {
  writing-mode: inherit;
  text-orientation: unset;
}

@media (max-width: 767px) {
  .mv-video {
    height: 350px;
    min-height: auto;
  }
}

.mv-video video,
.mv-video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
}

.sec-ttl {
  width: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
  height: calc(100% - 200px);
}

.sec-ttl img {
  width: 1em;
}

.sec-ttl._shop, .sec-ttl._contact {
  font-size: 15rem;
}

@media (max-width: 767px) {
  .sec-ttl {
    height: auto;
    font-size: 5rem;
  }
  .sec-ttl._shop, .sec-ttl._contact {
    font-size: 12rem;
  }
}

.mv-border {
  background: #000;
  color: #fff;
  text-align: center;
}

.mv-border-txt {
  text-align: center;
  font-size: 2.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.mv-border-txt span {
  margin-left: 4em;
}

@media (max-width: 767px) {
  .mv-border-txt {
    font-size: 1.6rem;
    height: auto;
    padding: 0.25em 0;
  }
  .mv-border-txt span {
    margin-left: 0;
    display: block;
  }
}

.header-drawer {
  margin-left: auto;
}

/* =============================================================================


     silde


============================================================================= */
.slide-box {
  position: relative;
  margin: 0;
  padding: 0;
}

/* slide */
.slide {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.slide-item {
  position: relative;
  height: 100%;
}

.slide-item a {
  display: block;
}

.slide-item:before {
  display: block;
  padding-top: 58%;
  content: "";
}

.slide-item img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
  height: 100%;
}

.slide > .slick-list,
.slide > .slick-list > .slick-track,
.slide > .slick-list > .slick-track > .slick-slide > div {
  position: relative;
  height: 100%;
}

/* slide-navigation */
.slide-navigation {
  line-height: 0;
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: 40px;
}

.slide-nav {
  position: relative;
  cursor: pointer;
}

.slide-img {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.slide-img:before {
  display: block;
  padding-top: 50%;
  content: "";
}

.slide-img:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  background: #000;
}

.slide-img img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  object-fit: cover;
  font-family: "object-fit:cover";
  height: 100%;
}

.slide-txt {
  color: #fff;
  font-size: 2rem;
  right: 5%;
  position: absolute;
  bottom: 5%;
  word-break: keep-all;
  width: 8em;
}

@media (max-width: 767px) {
  .slide-txt {
    font-size: 1rem;
  }
}

.slick-current .slide-img:after {
  opacity: 0.5;
}

@media screen and (max-width: 767px) {
  .slide-navigation {
    width: 100%;
  }
  .slide-navigation .item .image::before {
    padding-top: 50%;
  }
}

.drawer {
  z-index: 40;
  width: 100%;
}

@media (max-width: 767px) {
  .drawer {
    width: auto;
  }
}

.drawer-close {
  display: none;
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.drawer-close.is-checked {
  display: block;
  opacity: 0.4;
}

.drawer-icon {
  display: none;
  position: relative;
  z-index: 41;
  text-align: center;
  cursor: pointer;
  background-color: inherit;
  border: none;
}

@media (max-width: 767px) {
  .drawer-icon {
    display: inline-block;
  }
}

.drawer-icon:focus {
  outline: none;
}

.drawer-icon::after {
  content: "MENU";
  display: none;
  color: #424242;
  font-size: 0.8rem;
  text-align: center;
  line-height: 1;
  margin-top: 4px;
  font-weight: 700;
}

.drawer-icon.is-checked::after {
  content: "CLOSE";
}

.drawer-icon.is-checked + body {
  overflow: hidden;
}

.drawer-bars {
  display: inline-block;
  width: 25px;
  height: 19px;
  position: relative;
  vertical-align: bottom;
}

.drawer-bar {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  background: #000;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  transition: all 0.3s linear 0s;
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 8px;
}

.drawer-bar:nth-of-type(3) {
  top: 16px;
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 10px;
  transform: rotate(-45deg);
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 10px;
  transform: rotate(45deg);
}

@media (max-width: 767px) {
  .drawer-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateX(105%);
    z-index: 40;
    width: 200px;
    max-width: 90%;
    height: 100%;
    background: #f2f2f2;
    transition: all 0.5s ease 0s;
    padding-top: 100px;
    text-align: left;
    color: #000;
  }
  .drawer-content.is-checked {
    transform: translateX(0);
    box-shadow: 6px 0 25px rgba(0, 0, 0, 0.16);
  }
}

@media (max-width: 767px) {
  .drawer-content--left {
    transform: translateX(-105%);
    left: 0;
    right: auto;
  }
  .drawer-content--top {
    transform: translateY(-105%);
    top: 0;
    bottom: auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
  .drawer-bg.is-checked {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
    transition: 0.4s;
  }
}

.gnav {
  text-align: center;
}

.gnav-item {
  font-size: 1.6rem;
  margin: 20px 0;
  padding-bottom: 1em;
}

body.is-checked {
  overflow: hidden;
}

.gnav-item {
  margin: 20px 0;
  position: relative;
}

.sp-btn {
  position: absolute;
  top: 0em;
  left: 80%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.gnav-child-list {
  display: none;
  font-size: 80%;
}

.gnav-child-item {
  padding: 10px 0;
}

.breadcrumb {
  background-image: url(/dist/images/common/bread.jpg);
  background-repeat: repeat;
  background-position: center;
  height: 35px;
  font-weight: 300;
}

.breadcrumb-contents {
  display: flex;
  align-items: center;
  height: 100%;
}

.breadspan {
  display: inline-block;
  margin: 0 0.25em;
}

#dialog {
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 9999999999;
}

#dialog .dialog-box {
  background-color: #68a17d;
  box-shadow: 2px 2px 4px #333;
  color: #fff;
  left: 50%;
  padding: 30px 20px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 9999999999;
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    min-width: 250px;
  }
}

#dialog .dialog-text {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 20px;
}

@media (max-width: 1014px) {
  #dialog .dialog-text {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#dialog button {
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 30px;
  position: relative;
}

#dialog button:hover {
  opacity: 0.7;
}

/* =============================================================================


     footer


============================================================================= */
.footer {
  position: relative;
}

@media (max-width: 767px) {
  .footer {
    margin-bottom: 50px;
  }
}

.footer-container {
  margin: 0px auto 0;
  background: url(../img/footer-bg.png);
  background-size: cover;
  padding: 32px 0;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  height: 150px;
}

@media (max-width: 767px) {
  .footer-nav {
    flex-direction: column;
    height: auto;
    padding: 20px 0;
    margin: auto;
  }
}

.footer-item {
  color: #fff;
  font-size: 1.8rem;
}

.footer-item + li {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .footer-item + li {
    margin: 20px 0 0 0;
  }
}

.footer-link {
  display: block;
  transition: all 0.2s;
}

.footer-link img {
  height: 16px;
}

.footer-link:hover {
  opacity: 0.7;
}

.footer-copy {
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-name {
  font-size: 2rem;
  margin-bottom: 0.5em;
}

@media (max-width: 767px) {
  .footer-name {
    font-size: 1.8rem;
  }
}

.footer-address {
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .footer-address {
    font-size: 1.6rem;
  }
}

.footer-address a {
  margin-left: 2em;
  display: inline-block;
  transition: 0.2s;
}

.footer-address a:hover {
  opacity: 0.7;
}

@media (max-width: 767px) {
  .footer-address a {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .floating {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 5;
    background: #eee;
    padding: 10px 0;
    box-shadow: 2px 2px 4px #333;
    text-align: center;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .floating a {
    display: inline-block;
    transition: all 0.2s;
    margin-left: 1em;
    font-size: 2rem;
  }
  .floating a:hover {
    opacity: 0.7;
  }
  .floating img {
    height: 30px;
    margin-right: 2em;
  }
}

.footer-child-list {
  text-align: center;
}

.footer-child-item {
  margin: 5px 0;
  font-size: 1.4rem;
}

.footer-child-item a {
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
}

.footer-child-item a:hover {
  opacity: 0.7;
}

/* **************************************


           sub-footer


***************************************/
.sub-footer {
  position: relative;
  background: url(../img/common/sub_footerbg.jpg);
  background-size: cover;
  background-position: center;
  padding: 20px 0 40px;
  text-align: justify;
}

@media (max-width: 767px) {
  .sub-footer {
    margin-top: -80px;
    padding-bottom: 0;
  }
}

.sub-footer._index {
  background: none;
}

@media (max-width: 767px) {
  .sub-footer._index {
    padding-bottom: 0;
  }
}

.sub-footer-img {
  position: absolute;
  right: 0px;
  bottom: -115px;
  overflow: hidden;
}

.sub-footer-img img {
  transform: rotate(3deg);
}

@media (max-width: 767px) {
  .sub-footer-img {
    position: relative;
    right: auto;
    margin: auto;
    bottom: -25px;
  }
}

.sub-footer-container {
  display: inline-block;
  margin-left: 250px;
  text-align: center;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .sub-footer-container {
    margin: auto;
    max-width: 430px;
  }
}

.sub-footer-logo {
  text-align: center;
}

.sub-footer-box {
  text-align: center;
  padding: 0 40px;
}

@media (max-width: 767px) {
  .sub-footer-box {
    padding: 0 20px;
  }
}

.sub-footer-sns {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .sub-footer-sns {
    margin: 10px 0 0 auto;
  }
}

.sub-footer-sns li {
  display: block;
  transition: all 0.2s;
  height: 30px;
}

.sub-footer-sns li:hover {
  opacity: 0.7;
}

.sub-footer-sns li img {
  width: 30px;
}

.sub-footer-sns li + li {
  margin-left: 5px;
}

.sub-footer-tel {
  display: block;
  transition: all 0.2s;
  margin-top: 10px;
}

.sub-footer-tel:hover {
  opacity: 0.7;
}

.sub-footer-tel img {
  width: 100%;
}

.sub-footer-list {
  margin-top: 10px;
  text-align: justify;
}

.sub-footer-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.sub-footer-item dt {
  width: 4em;
  margin-right: 2em;
}

@media (max-width: 767px) {
  .floating {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 5;
    background: #eee;
    padding: 10px 0;
    box-shadow: 2px 2px 4px #333;
    text-align: center;
  }
  .floating a {
    display: inline-block;
    font-size: 2rem;
    transition: all 0.2s;
  }
  .floating a:hover {
    opacity: 0.7;
  }
  .floating img {
    height: 30px;
    margin-right: 2em;
  }
}

/* =============================================================================


     header


============================================================================= */
#header {
  position: relative;
  z-index: 2;
  height: 80px;
}

@media (max-width: 767px) {
  #header {
    min-width: unset;
    position: fixed;
    z-index: 20;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
  }
}

#header._index, #header._order {
  position: absolute;
  top: 0;
}

@media (max-width: 767px) {
  #header._index, #header._order {
    position: fixed;
  }
}

.header-contents {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.header-logo a {
  transition: 0.2s;
}

.header-logo a:hover {
  opacity: 0.7;
}

/* #header.transform .header-logo {
  padding: 25px 25px 25px 0;

  &:before {
    left: -25px;
    width: 25px;
  }

  img {
    width: 215px;
  }
} */
.header-sns-container {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .header-sns-container {
    margin-left: 24px;
  }
}

.header-sns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.header-sns-item {
  margin-left: 16px;
  height: 30px;
}

@media (max-width: 767px) {
  .header-sns-item {
    margin-left: 8px;
  }
}

.header-nav {
  text-align: center;
  font-size: 2rem;
}

.header-item {
  margin-top: 1em;
}

.header-link {
  position: relative;
  padding: 0 1.2em;
  display: inline-block;
}

.header-link:before {
  content: "●";
  color: #c69c6d;
  font-size: 80%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.tab-none {
  display: block;
}

@media (max-width: 1365px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1365px) {
  .tab-dis {
    display: block;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
